import React from 'react';
import Button from '@material-ui/core/Button';
import {toServiceLink} from './toServiceLink'

function LoginButton(props) {
  return (<Button variant="contained" color="primary" onClick={toServiceLink}>
    {props.text}
  </Button>);
}

export default LoginButton;
