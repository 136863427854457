import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NextStepLink from './NextStepLink';
import ImgWithShadow from './ImgWithShadow';

import realtimeSearchingImg from '../img/realtime_searching.gif';
import addingFilterImg from '../img/adding_filter.gif';
import addingDataItemImg from '../img/adding_data_item.gif';
import taxonCounterImg from '../img/taxon_counter.gif';
import csvImg from '../img/csv.jpg';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "60px"
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "10px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));

function Searching() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>データリスト画面</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>リアルタイム検索</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          入力に合わせて検索結果がリアルタイムに変わっていきます。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={realtimeSearchingImg} />
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>検索項目の追加</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          デフォルトで表示されている検索項目以外の項目も検索窓を表示させて検索することができます。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={addingFilterImg} />
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>表示項目の追加</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          リスト右上の黒い四角が3つ横に並んだボタンを押すと、デフォルトで表示されているデータ項目以外の項目も追加することができます。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={addingDataItemImg} />
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>所持タクソンカウンター</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          標本データのリスト画面では、リストの上に所持タクソンカウンターが表示されます。種数、科数など指定した分類階級の標本をいくつ所持しているか計算できます。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          リスト検索バーによる絞り込みはここでも有効ですので、指定条件内での種数カウントなどにご活用ください。かなり複雑な条件でのカウントも可能です。
        </Typography>
        <ul>
          <li>例: 機関コードで検索し、特定のコレクション内の種数をカウントする</li>
          <li>例: 県名で検索し、特定の県で採集した科数をカウントする</li>
          <li>例: 採集者名を自分、科名をLucanidaeで検索し、自己採集クワガタの種数をカウントする</li>
          <li>複雑な例: 指定座標から指定半径内の標高〇m〜〇mで〇年〇月〇日以降〇年〇月〇日以前に〇〇さんが採集した〇〇博物館納品用コレクションのゴミムシダマシ科の所持属数をカウントする</li>
        </ul>
        <ImgWithShadow isSmallImg={false} img={taxonCounterImg} />
      </Box>

      <Box className={classes.section}>
      <Typography variant="h5" className={classes.subtitle}>CSVダウンロード</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          「標本」「カスタム分類情報」「デフォルト分類情報」「採集地点」についてはリスト右上の「CSVをDL」ボタンからCSVデータをダウンロードすることができます。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          ダウンロードできるCSVには3種類あります。ノーマルのCSVはBOMなしUTF-8、Excel用CSVはBOM付きUTF-8、個人収蔵.com様への投稿用CSVはShift_JISでエンコードされています。
          なお、個人収蔵.com様への投稿用CSVはShift_JISの制約により、Ōなどのアクセント記号付き文字が通常のアルファベットに変換されてしまうことをご了承ください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          「個人収蔵.com用CSVをDL」ボタンを押すと、自動で対象標本の「個人収蔵.comに投稿済み?」の項目が投稿済みに変更されます。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          検索をかけていない場合は全データをダウンロードします。検索をかけている場合は検索条件に合うデータのみをダウンロードします。
          個人収蔵.com様への投稿用CSVについては投稿可かつ未投稿に設定された標本のみがダウンロードされます。検索は有効です。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={csvImg} />
      </Box>

      <Box className={classes.section}>
        <NextStepLink title="次のステップ" link="/manual/labeling/" text="ラベルの作成" />
      </Box>
    </Container>
  </Box>);
}

export default Searching;
