import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NextStepLink from './NextStepLink';
import ImgWithShadow from './ImgWithShadow';

import firstTimeImg from '../img/first_time.jpg';
import hasRegisteredImg from '../img/has_registered.jpg';
import collectionSettingImg from '../img/collection_setting.jpg';
import customClassification from '../img/custom_classification.jpg';
import tourLogImg from '../img/tour_log.jpg';
import collectPointImg from '../img/get_coordinate.gif';
import registerSpecimensImg from '../img/register_specimens.gif';
import makeBaseImg from '../img/make_base.gif';
import kojinShuzoButtonImg from '../img/kojin_shuzo_button.jpg'


const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "60px"
  },
  ul: {
    margin: "0",
    paddingLeft: "10px"
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "10px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  supportingText: {
    marginTop: "20px",
    marginBottom: "10px",
    color: "#666666"
  },
  liProcess: {
    marginBottom: "15px",
    listStyle: "none"
  }
}));

function LiProcess(props) {
  const classes = useStyles();

  return (<li className={classes.liProcess}>
    <Typography variant="body1" className={classes.bodyText}>&rarr;&nbsp;{props.text}</Typography>
  </li>);
}

function Registering() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>標本登録の流れ</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>
          本サービスの特徴は「分類情報」と「採集地点」を標本データと切り離していることです。これらのデータを標本データとは別で作成し、標本データ登録の際に両者を関連付けるという手順を踏む必要があります。最初はとっつきにくいかもしれませんが、この仕組みによって同じ分類情報や採集地点を持つ標本については再び同じデータを入力せずに済みます。
        </Typography>
        <Box m={3}/>
        <Typography variant="body1" className={classes.bodyText}>基本的に本サービスにおける標本登録手順は</Typography>
        <ul className={classes.ul}>
          <LiProcess text="コレクション設定作成"/>
          <LiProcess text="カスタム分類情報作成(デフォルト分類情報を利用する場合はスキップ)"/>
          <LiProcess text="採集行情報作成(省略可能)"/>
          <LiProcess text="採集地点作成"/>
          <LiProcess text="標本にこれらのデータを関連付け、標本固有の情報を入力して登録"/>
        </ul>
        <Typography variant="body1" className={classes.bodyText}>となります。</Typography>
        <Box m={3}/>
        <Typography variant="body1" className={classes.bodyText}>初回利用時にはまだデータが無いので、空のリスト画面が表示されます。</Typography>
        <ImgWithShadow isSmallImg={false} img={firstTimeImg}/>
        <Box m={6}/>
        <Typography variant="body1" className={classes.bodyText}>2回目以降はデータリスト画面右上に作成ボタン(スマホ版では右下)が表示されますので、そこからデータを登録してください。</Typography>
        <ImgWithShadow isSmallImg={false} img={hasRegisteredImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>
          <strong>ステップ1:</strong>&nbsp;コレクション設定の作成</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          標本が所属するコレクションの設定を作りましょう。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={collectionSettingImg}/>
        <Typography variant="body1" className={classes.bodyText}>
          本サービスで初めて標本にIDを振る方は「標本IDの最新番号」をゼロにしてください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          これまで他のシステムで標本にIDを振っていて途中から本サービスに移行し、新たに登録する標本にIDを振りたい方は、現時点での最新の番号を入力してください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          標本データを登録する際は、IDに手動で値を入力しない限り、この「標本IDの最新番号」を起点に自動加算されていきます。標本IDを自動加算で登録する度に「標本IDの最新番号」も自動で加算されていきます。
        </Typography>
        <Typography variant="body1" className={classes.supportingText}>
          <i>ヒント:&nbsp;他システムから移行の際、新たに登録する標本については「標本IDの最新番号」からの自動加算、過去に他システムで登録していたIDの移行については手動入力で使い分けるといいです</i>
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>
          <strong>ステップ2:</strong>&nbsp;カスタム分類情報作成
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          分類情報には運営が用意した「デフォルト分類情報」とユーザー自身が作る「カスタム分類情報」の2種類があります。現時点では日本産甲虫ほぼ全種・日本産蛾類ほぼ全種が「デフォルト分類情報」に登録されています。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          「デフォルト分類情報」に登録されている種を標本と紐付ける場合はこのステップは不要です。「デフォルト分類情報」に無い種を登録したい場合、また「デフォルト分類情報」にある種でも異なる分類学的見解を採用したい場合は「カスタム分類情報」を作成します。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          全ての入力項目を埋める必要はありませんが、標本との紐付けの際に属・種・亜種・和名のいずれかに対して検索する必要があるので、もし属以下まで同定できていない分類情報を作成する際は和名欄に「未同定アゲハチョウ科」などと入力することで検索可能にしておいてください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          「デフォルト分類情報」に対して「これをベースに作成」ボタンを使用すると、その「デフォルト分類情報」のデータをベースに「カスタム分類情報」を作成することができます。「デフォルト分類情報」の一部項目のみを変更したい場合にはこの方法を使ってください。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={customClassification}/>
        <Typography variant="body1" className={classes.supportingText}>
          <i>ヒント:&nbsp;全ての入力項目を埋める必要は無いので、例えばよく使うする科まで入力しておき、検索用に和名欄に「アゲハチョウ科」などと入力して作成しておくことで、今後同じ科の分類情報を作成する際のベースにしたり、上位分類までは同定できているが種まで落とせていない標本を入力したりする時に無駄な再入力をせずに済みます</i>
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>
          <strong>ステップ3:</strong>&nbsp;採集行作成</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          現時点でこの「採集行」はあまり意味がありませんので省略して頂いても構いません(不必要な項目は空欄でもOKです)。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          今後gpxやkmlで採集ルートのGPS記録をアップロードできる機能が実装された際には役立つ予定です。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={tourLogImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>
          <strong>ステップ4:</strong>&nbsp;採集地点作成</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          地名や座標・標高などのデータを入力してください。不要な項目は空欄でもOKですが、国名および日本語地名(詳細)は必須です。日本語地名(詳細)は標本データとの紐付け検索に使用されるので、他のデータと区別しやすい内容にしてください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          登録フォームの右側に入力補助マップを用意しております。検索またはクリックした地点の座標・標高・地名・地名の読みがな・地名のローマ字表記を表示しますので、ボタンからコピーしてご利用ください。ボタンが行うのはコピーのみでフォームへのペーストは自動で行われないことにご注意ください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          なお現時点では標高・地名の入力補助は日本国内のみの対応となります。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          より良い入力補助マップを目指して改善中です。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={collectPointImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>
          <strong>ステップ5:</strong>&nbsp;標本情報作成</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          採集者や採集日や管理方法などの標本固有のデータを入力し、これまで作ってきたコレクション設定、採集地点、そしてデフォルト分類情報かカスタム分類情報のどちらかと紐付けます(不要な項目は空欄でもOKです)。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          標本IDを自動連番にしたい場合は必ず「標本ID」欄を空にしてください。特に「これをベースに作成」を利用して既に標本IDのある標本データを元に登録する際に空にし忘れやすいのでご注意ください。もし誤って空にし忘れた場合は、標本データの編集画面からIDを変更し、コレクション設定の編集画面から「標本IDの最新番号」を変更してください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          同一データの標本を多数登録する場合、「同一データ標本の作成数」欄に登録数を入力した上で、「標本ID」欄を空欄にして登録してください。登録数が多い場合、登録に少し時間がかかります。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          個人収蔵.com様に投稿したい標本は「個人収蔵.comへの投稿の可否」トグルをオン、「個人収蔵.comに投稿済み?」トグルをオフにしてください。
        </Typography>
        <ImgWithShadow isSmallImg={true} img={kojinShuzoButtonImg}/>
        <Typography variant="body1" className={classes.bodyText}>
          「採集地点」などの外部データとの紐付け検索は、検索窓への入力内容からリアルタイムに候補リストを表示する方式です。ただし「デフォルト分類情報」のみ検索の方法が異なり、検索窓への入力後に検索ボタンを押してから検索結果リストを手動で開く必要があります。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={registerSpecimensImg}/>
      </Box>

      <Box className={classes.section}>
        <NextStepLink title="次のステップ" link="/manual/searching/" text="データリスト画面"/>
      </Box>
    </Container>
  </Box>);
}

export default Registering;
