import React from 'react';
import Box from '@material-ui/core/Box';

import {useWindowDimensions} from './useWindowDimensions';

function ImgWithShadow(props) {
  const {width, height} = useWindowDimensions();

  if (props.isSmallImg == true) {
    if (width > 550) {
      return (<Box m={2}>
        <Box px={10}>
          <Box borderRadius="4px" boxShadow={3}>
            <img src={props.img} width="100%" height="auto" style={{
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px"
              }}/>
          </Box>
        </Box>
      </Box>);
    } else {
      return (<Box m={2}>
        <Box px={2}>
          <Box borderRadius="4px" boxShadow={3}>
            <img src={props.img} width="100%" height="auto" style={{
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px"
              }}/>
          </Box>
        </Box>
      </Box>);
    }
  } else {
    return (<Box m={2}>
      <Box borderRadius="4px" boxShadow={3}>
        <img borderRadius={3} src={props.img} width="100%" height="auto" style={{
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px"
          }}/>
      </Box>
    </Box>);
  }
}

export default ImgWithShadow;
