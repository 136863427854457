import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "80px"
  },
  subtitle: {
    marginTop: "20px"
  },
  ul: {
    margin: "0",
    padding: "20px",
    paddingTop: "0",
    paddingRight: "0"
  },
  li: {
    marginTop: "15px",
    marginBottom: "15px"
  },
  liText: {
    marginTop: "5px",
    marginBottom: "5px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));

function LiBody1(props) {
  const classes = useStyles();

  return (<li className={classes.li}>
    <Typography variant="body1" className={classes.liText}>{props.text1}</Typography>
    <Typography variant="body1" className={classes.liText}>{props.text2}</Typography>
  </li>);
}

function Techinfo() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>技術情報</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>
          本サービスで採用している主な技術は以下の通りです。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          作者の技術力不足のため実現できていない機能が多々あるので、以下の技術に精通している方がおられましたら、 開発にご協力して頂けると嬉しいです。
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>バックエンド</Typography>
        <ul className={classes.ul}>
          <LiBody1 text1="言語: " text2="Python"/>
          <LiBody1 text1="フレームワーク: " text2="Django REST Framework"/>
          <LiBody1 text1="データベース: " text2="PostGIS (PostgreSQLの地理情報機能拡張)"/>
          <LiBody1 text1="サーバOS: " text2="Linux (Ubuntu Server)"/>
          <LiBody1 text1="アプリケーションサーバ: " text2="Gunicorn"/>
          <LiBody1 text1="Webサーバ: " text2="Nginx"/>
        </ul>

        <Typography variant="h5" className={classes.subtitle}>フロントエンド</Typography>
        <ul className={classes.ul}>
          <LiBody1 text1="言語: " text2="JavaScript (React)"/>
          <LiBody1 text1="フレームワーク: " text2="React-Admin"/>
        </ul>

        <Typography variant="h5" className={classes.subtitle}>認証・セキュリティ</Typography>
        <ul className={classes.ul}>
          <li className={classes.li}>
            <Typography variant="body1" className={classes.bodyText}>Auth0</Typography>
          </li>
        </ul>

        <Typography variant="body1" className={classes.bodyText}>
          本サービスはBSDライセンスのオープンソースプロジェクトです。(GitHub:&nbsp;
          <Link href="https://github.com/fujikawahiroaki/webspecimanager" underline="always" target="_blank" rel="noopener noreferrer" style={{
              wordWrap: "break-word"
            }}>
            https://github.com/fujikawahiroaki/webspecimanager
          </Link>)
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          大事な標本データを他者のサーバに預けるのがご不安な方は、ご自身のPCやサーバ上に本サービスのクローンを構築することもできます。
        </Typography>
      </Box>
    </Container>

  </Box>);
}

export default Techinfo;
