import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "90px"
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "10px"
  },
  bodyText: {
    marginTop: "15px",
    marginBottom: "15px"
  },
  noDecoration: {
    textDecoration: "none"
  }
}));

function NextStepLink(props) {
  const classes = useStyles();

  return (<Box>
    <Typography variant="h5" className={classes.bodyText}>{props.title}</Typography>
    <Box m={3}>
      <RouterLink to={props.link} className={classes.noDecoration}>
        <Link color="primary">
          <Typography component="span">
            <strong>
              {props.text}&nbsp;>
            </strong>
          </Typography>
        </Link>
      </RouterLink>
    </Box>
  </Box>
  );
}

export default NextStepLink;