import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "80px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));


function LiBody1(props) {
  const classes = useStyles();

  return (<li>
    <Typography variant="body1" className={classes.li}>{props.text}</Typography>
  </li>);
}


function Notice() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>免責事項</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>本サービスは藤川浩明があくまで個人の趣味として開発・運営しているサービスです。</Typography>
        <Typography variant="body1" className={classes.bodyText}>ご意見・ご要望にはなるべくお応えできるよう努力いたしますが、限界があることはあらかじめご了承ください。</Typography>
        <Typography variant="body1" className={classes.bodyText}>また藤川の本業はプログラマではなく、あくまで趣味で開発しているだけなので、告知していた実装予定機能は藤川の作業時間や技術力の不足により実装されない可能性もあります。</Typography>
        <Typography variant="body1" className={classes.bodyText}>単なる趣味サービスではありますが、万一サービス終了することになった場合は利用者様のデータ救済措置を必ず実施いたしますので、その点はご安心ください。</Typography>
        <Box m={3}>
          <Typography variant="h4" className={classes.center}>プライバシー・ポリシー</Typography>
        </Box>
        <Typography variant='h6'>第1条 個人情報</Typography>
        <Typography variant="body1" className={classes.bodyText}>本サービスを利用するにあたり、以下のプライバシー・ポリシーが適用されます。利用者様は、本サービスを利用することにより、本プライバシー・ポリシーに同意したものとみなされます。本プライバシー・ポリシーにおいて、個人情報とは、個人情報保護法により定義された個人情報及び特定の個人情報に結びついて使用される個人に関する属性情報（氏名・メールアドレス・利用履歴等）を意味するものとします。</Typography>

        <Typography variant="h6">第2条 個人情報の管理</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          本サービスでは利用者様の個人情報を保持するにあたり、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するために適切な措置を講じ、安全対策を実施し個人情報の管理を行ないます。
        </Typography>
        <Typography variant="h6">第3条 個人情報の利用目的</Typography>
        <ul>
          <LiBody1 text="本サービスの提供・運営のため" />
          <LiBody1 text="ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）" />
          <LiBody1 text="メンテナンス、重要なお知らせなど必要に応じたご連絡のため" />
          <LiBody1 text="利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため" />
          <LiBody1 text="ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため" />
        </ul>
        <Typography variant='h6'>第4条 利用目的の変更</Typography>
        <Typography variant="body1" className={classes.bodyText}>管理人は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
          利用目的の変更を行った場合には、変更後の目的についてユーザーに通知し、または本ウェブサイト上に公表するものとします。
        </Typography>
        <Typography variant='h6'>第5条 個人情報の第三者提供</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          管理人は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
        </Typography>
        <ul>
          <LiBody1 text="人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき" />
          <LiBody1 text="公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき" />
          <LiBody1 text="国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき" />
          <LiBody1 text="予め次の事項を告知あるいは公表し、かつ管理人が個人情報保護委員会に届出をしたとき" />
          <ul>
            <LiBody1 text="利用目的に第三者への提供を含むこと" />
            <LiBody1 text="第三者に提供されるデータの項目" />
            <LiBody1 text="第三者への提供の手段または方法" />
            <LiBody1 text="本人の求めに応じて個人情報の第三者への提供を停止すること" />
            <LiBody1 text="本人の求めを受け付ける方法" />
          </ul>
        </ul>
        <Typography variant="body1" className={classes.bodyText}>
          前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
        </Typography>
        <ul>
          <LiBody1 text="管理人が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合" />
          <LiBody1 text="個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合" />
        </ul>
        <Typography variant='h6'>第6条 個人情報の開示 </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          管理人は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
        </Typography>
        <ul>
          <LiBody1 text="本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合" />
          <LiBody1 text="本サービス管理の適正な実施に著しい支障を及ぼすおそれがある場合" />
          <LiBody1 text="その他法令に違反することとなる場合" />
        </ul>
        <Typography variant="body1" className={classes.bodyText}>
          前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
        </Typography>
        <Typography variant='h6'>第7条 個人情報の訂正および削除</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          ユーザーは、管理人の保有する自己の個人情報が誤った情報である場合には、管理人が定める手続きにより、管理人に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
          管理人は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。
          管理人は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
        </Typography>
        <Typography variant='h6'>第8条 個人情報の利用停止等</Typography>
        <Typography variant="body1" className={classes.bodyText}>管理人は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
          前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
          管理人は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
          前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
        </Typography>
        <Typography variant='h6'>第9条 プライバシーポリシーの変更</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
          管理人が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
        </Typography>
        <Typography variant='h6'>第10条 お問い合わせ</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          本ポリシーに関するお問い合わせは、管理人の藤川浩明(fhir0aki3@gmail.com)までお願いいたします。
        </Typography>
      </Box>
    </Container>

  </Box>);
}

export default Notice;
