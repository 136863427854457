import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer"
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoginButton from './LoginButton';
import Logo from '../img/logo.png';
import {toServiceLink} from './toServiceLink';
import {useWindowDimensions} from './useWindowDimensions';
import {LogoButton, LinkButton, HeaderLoginButton, LogoButtonAsListItem, LinkButtonAsListItem} from './HeaderComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textDecoration: "none"
  },
  black: {
    backgroundColor: "#000000"
  },
  text: {
    color: "#ffffff",
    textDecoration: "none"
  },
  lastIcon: {
    marginRight: "5px"
  }
}));

function ManualHeaderDesktop() {
  const classes = useStyles();

  return (<Box className={classes.root}>
    <AppBar position="fixed" className={classes.black}>
      <Toolbar>
        <LogoButton textColor="#ffffff"/>
        <LinkButton link="/manual/loggingin/" text="ログイン方法" textColor="#ffffff"/>
        <LinkButton link="/manual/registering/" text="標本登録の流れ" textColor="#ffffff"/>
        <LinkButton link="/manual/searching/" text="データリスト画面" textColor="#ffffff"/>
        <LinkButton link="/manual/labeling/" text="ラベルの作成" textColor="#ffffff"/>
        <HeaderLoginButton/>
      </Toolbar>
    </AppBar>
  </Box>);
}

function ManualHeaderMobile() {
  const classes = useStyles();

  return (<Box className={classes.root}>
    <AppBar position="fixed" className={classes.black}>
      <Toolbar>
        <TemporaryDrawer/>
        <LogoButton textColor="#ffffff"/>
        <HeaderLoginButton/>
      </Toolbar>
    </AppBar>
  </Box>);
}

function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({
      ...state,
      [anchor]: open
    });
  };

  const list = (anchor) => (<div className={classes.fullList} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
    <List>
      <LogoButtonAsListItem textColor="#000000"/>
      <Divider/>
      <LinkButtonAsListItem link="/manual/loggingin/" text="ログイン方法" textColor="#000000"/>
      <LinkButtonAsListItem link="/manual/registering/" text="標本登録の流れ" textColor="#000000"/>
      <LinkButtonAsListItem link="/manual/searching/" text="データリスト画面" textColor="#000000"/>
      <LinkButtonAsListItem link="/manual/labeling/" text="ラベルの作成" textColor="#000000"/>
    </List>

  </div>);

  return (<div>
    {
      ['left'].map((anchor) => (<React.Fragment key={anchor}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(anchor, true)}>
          <MenuIcon/>
        </IconButton>
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>))
    }
  </div>);
}

function ManualHeader() {
  const mobileWidth = 830;
  const {width, height} = useWindowDimensions();
  if (width > mobileWidth) {
    return (<ManualHeaderDesktop/>);
  } else {
    return (<ManualHeaderMobile/>);
  }
}

export default ManualHeader;
