import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  copyContainer: {
    marginTop: "auto",
    backgroundColor: "#f7f7f7"
  },
  copy: {
    color: "#727272"
  }
}));

function Footer() {
  const classes = useStyles();

  return (<Box className={classes.copyContainer}>
    <Divider/>
    <Box p={1} textAlign="center">
      <Typography variant="body2" className={classes.copy}>&copy; 2021 WebSpecimanager</Typography>
    </Box>
  </Box>);
}

export default Footer;
