import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import ImgWithShadow from './ImgWithShadow';

import NextStepLink from './NextStepLink';
import WelcomeImg from '../img/welcome.jpg'
import LoginImg from '../img/login.jpg';
import LoginGoogleImg from '../img/login_google.jpg';
import WhiteErrImg from '../img/white_err.jpg';
import {toServiceLink} from './toServiceLink'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "90px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));

function Features() {
  const classes = useStyles();

  return (<Box className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>ログイン方法</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>
          本サービスはGoogleアカウントまたはメールアドレス+パスワードのどちらかで登録できます。(どちらの方法で登録したか忘れないようにしてください)
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          なお安全のため、10時間に1回自動ログアウトされます。また、ブラウザのタブを閉じても自動ログアウトされますのでご注意ください。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={LoginImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>ログインは認証サービスAuth0を介して行われます。</Typography>
        <ImgWithShadow isSmallImg={false} img={LoginGoogleImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>ログインに成功するとダッシュボードが表示されます。まだ標本データが無いのでグラフはありません。標本データを登録するとグラフが表示されるようになります。</Typography>
        <ImgWithShadow isSmallImg={false} img={WelcomeImg}/>
      </Box>

      <Box className={classes.section}>
        <NextStepLink title="次のステップ" link="/manual/registering/" text="標本登録の流れ"/>
      </Box>
    </Container>
  </Box>);
}

export default Features;