import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import ImgWithShadow from './ImgWithShadow';

import LoginSection from './LoginSection';
import ReusingDataImg from '../img/reusing_data.gif';
import LikeDesktopAppImg from '../img/like_desktop_app.gif';
import ManyFunctions from '../img/many_functions.jpg'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "90px"
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "10px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));

function Features() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>特色</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>再利用可能なデータの独立</Typography>
        <Typography variant="body1" className={classes.bodyText}>再利用可能なデータ(採集地点・分類情報)を標本データから独立させ、それらのデータと標本データを紐付ける方式を採用しているため、いったん採集地点や分類情報を作成してしまえば、同じ採集地点や分類情報を持つ標本を登録する際は、同じ内容をいちいち再入力することなく、作成済みの採集地点や分類情報データとパパっと紐付けるだけで済みます。</Typography>
        <ImgWithShadow isSmallImg={false} img={ReusingDataImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>まるでデスクトップアプリケーションのような操作性</Typography>
        <Typography variant="body1" className={classes.bodyText}>データの検索や表示項目・検索項目の変更などをページの更新を挟むことなくシームレスに行うことができます。</Typography>
        <ImgWithShadow isSmallImg={false} img={LikeDesktopAppImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>様々な機能</Typography>
        <Typography variant="body1" className={classes.bodyText}>単にデータベースとして標本データを保存・検索できるだけではありません。ラベルPDFの自動生成、採集地点入力補助マップ、採集地点閲覧マップ、所持タクソンや採集地の割合を可視化するグラフ、所持タクソンカウンターなど様々な機能を持っています。</Typography>
        <ImgWithShadow isSmallImg={false} img={ManyFunctions}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>個人収蔵.com様との棲み分け</Typography>
        <Typography variant="body1" className={classes.bodyText}>Web上で標本データベースといえば
          <Link href="https://kojin-shuzo.com/" underline="always" target="_blank" rel="noopener noreferrer">個人収蔵.com</Link>
          様を思い浮かべる方も多いでしょうが、個人収蔵.com様は標本データの公開・共有を目的としているのに対し、本サービスはプライベートな標本管理を目的としているため、根本的に性質が異なり競合はしません。
          <br/>
          本サービスには個人収蔵.com様への投稿支援機能があります。詳しくはマニュアルをご参照ください。
        </Typography>
      </Box>
    </Container>

    <LoginSection/>
  </Box>);
}

export default Features;
