import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ImgWithShadow from './ImgWithShadow';

import labelingImg from '../img/labeling.gif';
import cuttingLabelImg from '../img/cutting_label.jpg';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "60px"
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "10px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  }
}));

function Labeling() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>ラベルの作成</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>ラベルデータの作成</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          「データ」「コレクション」「同定」「備考(採集方法)」の4種類の項目のうち必要な項目を選択し、所属標本リストからラベルを作りたい標本を選択してラベルデータを作成します。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          現時点ではラベル用紙はハガキサイズ(100×148mm)、フォントサイズは3pt、フォントはmigmixのみの対応となります。また学名は斜体になりません。今後改善予定です。現状の形式がご不満な場合、CSVダウンロード機能を利用してラベル印刷したい標本データのCSVを取得し自力でラベルを作ることもできます。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          印刷の際はリスト画面または詳細画面から「ラベルPDF生成」ボタンを押してください。
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          標本の選択方法が現状ではイマイチなので現在改善策を考えております。ご不便をおかけして申し訳ございませんが、もうしばらくお待ちください。
        </Typography>
        <ImgWithShadow isSmallImg={false} img={labelingImg}/>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>印刷したラベルの切り方</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          同一標本についての各ラベル項目が縦に連続して並ぶようになっています。 標本の境界がイマイチ分かりにくいので現在改造中です。 
        </Typography>
        <ImgWithShadow isSmallImg={false} img={cuttingLabelImg}/>
      </Box>

    </Container>
  </Box>);
}

export default Labeling;
