import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer"
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoginButton from './LoginButton';
import Logo from '../img/logo.png';
import {toServiceLink} from './toServiceLink';
import {useWindowDimensions} from './useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textDecoration: "none"
  },
  black: {
    backgroundColor: "#000000"
  },
  text: {
    color: "#ffffff",
    textDecoration: "none"
  },
  lastIcon: {
    marginRight: "5px"
  }
}));

export function LogoButton(props) {
  const classes = useStyles();
  const {width, height} = useWindowDimensions();

  if (width > 330) {
    return (<Box className={classes.title}>
      <Typography variant="h6">
        <Link underline="none">
          <RouterLink to="/" className={classes.text} style={{
              color: props.textColor
            }}>
            WebSpecimanager
          </RouterLink>
        </Link>
      </Typography>
    </Box>)
  } else {
    return (<Box className={classes.title}></Box>)
  }
}

export function LinkButton(props) {
  const classes = useStyles();

  return (<Box m={1}>
    <Link>
      <RouterLink to={props.link} className={classes.text} style={{
          color: props.textColor
        }}>
        <Typography>
          {props.text}
        </Typography>
      </RouterLink>
    </Link>
  </Box>)
}

export function LinkToManual(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<Box m={1}>
    <Button aria-controls="simple-menu" aria-haspopup="true" color="secondary" onClick={handleClick}>
      操作マニュアル
    </Button>
    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted="keepMounted" open={Boolean(anchorEl)} onClose={handleClose}>
      <Link underline="none">
        <RouterLink to="/manual/" target="_blank" style={{
            color: "#000000",
            textDecoration: "none"
          }}>
          <MenuItem onClick={handleClose}>
            マニュアルをひらく
          </MenuItem>
        </RouterLink>
      </Link>

    </Menu>
  </Box>)
}

export function HeaderLoginButton() {
  return (<Button variant="outlined" color="secondary" onClick={toServiceLink}>
    ログイン
  </Button>);
}

export function LogoButtonAsListItem(props) {
  const classes = useStyles();

  return (<Box m={1}>
    <Typography variant="h6">
      <Link underline="none">
        <RouterLink to="/" className={classes.text} style={{
            color: props.textColor
          }}>
          <ListItem>
            WebSpecimanager
          </ListItem>
        </RouterLink>
      </Link>
    </Typography>
  </Box>);
}

export function LinkButtonAsListItem(props) {
  const classes = useStyles();

  return (<Box m={1}>
    <Link underline="none">
      <RouterLink to={props.link} className={classes.text} style={{
          color: props.textColor
        }}>
        <ListItem button="true">
          <Typography>{props.text}</Typography>
        </ListItem>
      </RouterLink>
    </Link>
  </Box>)
}

export function LinkToManualAsLlistItem(props) {
  const classes = useStyles();

  return (<Box m={1}>
    <Link underline="none">
      <RouterLink to="/manual/" target="_blank" className={classes.text} style={{
          color: props.textColor
        }}>
        <ListItem button="true">
          <Typography>
            マニュアル
          </Typography>
        </ListItem>
      </RouterLink>
    </Link>
  </Box>);
}

export function LinkToNews(props) {
  return (<Box>
    <RouterLink to="/news/">
      <IconButton color={props.color}>
          <Typography>
            新着情報
          </Typography>
        <NotificationsIcon/>
      </IconButton>
    </RouterLink>
  </Box>);
}
