import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Header from './components/Header';
import ManualHeader from './components/ManualHeader';
import Top from './components/Top';
import Features from './components/Features';
import Functions from './components/Functions';
import Techinfo from './components/Techinfo';
import Notice from './components/Notice';
import Contact from './components/Contact';
import ManualTop from './components/ManualTop';
import Loggingin from './components/Loggingin';
import Registering from './components/Registering';
import Searching from './components/Searching'
import Labeling from './components/Labeling';
import News from './components/News';
import Footer from './components/Footer';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {theme} from './theme/theme';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
    <MuiThemeProvider theme={theme}>
    <ScrollToTop />
      <Switch>
        <Route path="/manual/" component={ManualHeader} />
        <Route path="/" component={Header} />
      </Switch>
        <Route path="/" exact component={Top} />
        <Route path="/features/" exact component={Features} />
        <Route path="/functions/" exact component={Functions} />
        <Route path="/techinfo/" exact component={Techinfo} />
        <Route path="/notice/" exact component={Notice} />
        <Route path="/contact/" exact component={Contact} />
        <Route path="/news/" exact component={News} />
        <Route path="/manual/" exact component={ManualTop} />
        <Route path="/manual/loggingin/" exact component={Loggingin} />
        <Route path="/manual/registering/" exact component={Registering} />
        <Route path="/manual/searching/" exact component={Searching} />
        <Route path="/manual/labeling/" exact component={Labeling} />
        <Footer />
        </MuiThemeProvider>
    </Router>
  );
}

export default App;
