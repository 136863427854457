import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Logo from '../img/logo.png';
import {useWindowDimensions} from './useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px"
  },
  center: {
    textAlign: "center"
  },
  marginAuto: {
    margin: "auto"
  },
  largeSpace: {
    marginTop: "150px"
  },
  ul: {
    margin: "0",
    padding: "20px",
    paddingLeft: "30px",
    paddingRight: "0"
  },
  li: {
    marginTop: "15px",
    marginBottom: "15px"
  },
  title: {
    color: "#1e1e1e"
  }
}));

export default function LogoAndTitle(props) {
  const classes = useStyles();
  const {width, height} = useWindowDimensions();

  if (width > 900) {
    return (<Box m={10}>
      <Grid container="container" spacing={1} alignItems="center" justify="center">
        <Grid item="item" sm={6} xs={12} className={classes.center}>
          <Typography variant="h3" className={classes.title}>
            <Box fontWeight="fontWeightBold">
              {props.titleText}
            </Box>
          </Typography>
          <Typography variant="h5" className={classes.title}>
            <Box fontWeight="fontWeightMedium">
              {props.subtitleText}
            </Box>
          </Typography>
        </Grid>
        <Grid item="item" sm={6} xs={12} className={classes.center}>
          <Box width="200px" height="200px" className={classes.marginAuto}>
            <img src={Logo} width="100%" height="100%"/>
          </Box>
        </Grid>
      </Grid>
    </Box>);
  } else if (width > 750) {
    return (<Box m={3}>
      <Grid container="container" spacing={1} alignItems="center" justify="center">
        <Grid item="item" sm={6} xs={12} className={classes.center}>
          <Typography variant="h3" className={classes.title}>
            <Box fontWeight="fontWeightBold">
              {props.titleText}
            </Box>
          </Typography>
          <Typography variant="h5" className={classes.title}>
            <Box fontWeight="fontWeightMedium">
              {props.subtitleText}
            </Box>
          </Typography>
        </Grid>
        <Grid item="item" sm={6} xs={12} className={classes.center}>
          <Box width="175px" height="175px" className={classes.marginAuto}>
            <img src={Logo} width="100%" height="100%"/>
          </Box>
        </Grid>
      </Grid>
    </Box>);
  } else {
    return (<Box m={1}>
      <Grid container="container" spacing={1} alignItems="center" justify="center">
        <Grid item="item" xs={12} className={classes.center}>
          <Typography variant="h4" className={classes.title}>
            <Box fontWeight="fontWeightBold">
              {props.titleText}
            </Box>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            <Box fontWeight="fontWeightMedium">
              {props.subtitleText}
            </Box>
          </Typography>
        </Grid>
        <Grid item="item" xs={12} className={classes.center}>
          <Box width="150px" height="150px" className={classes.marginAuto}>
            <img src={Logo} width="100%" height="100%"/>
          </Box>
        </Grid>
      </Grid>
    </Box>);
  }
}
