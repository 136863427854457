import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';

import NextStepLink from './NextStepLink';
import Logo from '../img/logo.png';
import Img from '../img/thumbnail.jpg';
import LogoAndTitle from './LogoAndTitle';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  marginAuto: {
    margin: "auto"
  },
  noDecoration: {
    textDecoration: "none"
  }
}));

function ManualTop() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <LogoAndTitle titleText="WebSpecimanager" subtitleText="操作マニュアル"/>

      <Box mt={15}>
        <NextStepLink title="最初のステップ" link="/manual/loggingin/" text="ログイン方法"/>
      </Box>
    </Container>
  </Box>);
}

export default ManualTop;
