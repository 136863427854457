import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "80px"
  },
  bodyText: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  contact: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  ul: {
    margin: "0",
    padding: "15px",
    paddingTop: "5px",
    paddingRight: "0"
  },
  li: {
    marginBottom: "20px"
  }
}));

function LiBody1(props) {
  const classes = useStyles();

  return (<li>
    <Typography variant="body1" className={classes.bodyText}>{props.text}</Typography>
  </li>);
}

function Contact() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>ご意見・ご要望</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="body1" className={classes.bodyText}>ご意見・ご要望は運営者の藤川にメールまたはTwitterのDMでよろしくお願いいたします。</Typography>
        <ul className={classes.ul}>
          <li className={classes.li}>
            <Typography variant="body1" className={classes.contact}>メール:</Typography>
            <Typography variant="subtitle1" className={classes.contact}>
              <Box fontWeight="fontWeightBold">fhir0aki3@gmail.com</Box>
            </Typography>
          </li>
          <li className={classes.li}>
            <Typography variant="body1" className={classes.contact}>Twitter:</Typography>
            <Typography variant="subtitle1" className={classes.contact}>
              <Box fontWeight="fontWeightBold">@webspecimanager</Box>
            </Typography>
          </li>
        </ul>
      </Box>
    </Container>

  </Box>);
}

export default Contact;
