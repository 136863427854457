import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import LoginSection from './LoginSection'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "70px"
  },
  subtitle: {
    marginTop: "20px"
  },
  subsubtitle: {
    marginTop: "10px"
  },
  ul: {
    margin: "0",
    padding: "15px",
    paddingTop: "5px",
    paddingRight: "0"
  },
  li: {
    marginTop: "15px",
    marginBottom: "15px"
  }
}));

function LiBody1(props) {
  const classes = useStyles();

  return (<li>
    <Typography variant="body1" className={classes.li}>{props.text}</Typography>
  </li>);
}

function Functions() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>機能</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>現時点での主な機能</Typography>
        <ul className={classes.ul}>
          <LiBody1 text="採集地点・分類情報の再利用"/>
          <LiBody1 text="ラベルPDF自動生成"/>
          <LiBody1 text="日本産甲虫および日本産蛾類ほぼ全種の分類情報を「デフォルト分類情報」としてあらかじめ用意"/>
          <LiBody1 text="緯度・経度・標高・地名の入力補助マップ(標高と地名は現時点では日本国内のみ)"/>
          <LiBody1 text="採集地点マップ"/>
          <LiBody1 text="所持タクソン割合グラフ・採集地割合グラフ"/>
          <LiBody1 text="所持タクソンカウンター"/>
          <LiBody1 text="データのCSVダウンロード"/>
          <LiBody1 text="個人収蔵.com様への投稿支援機能"/>
        </ul>
        <Typography>日本産甲虫については<a href="https://japanesebeetles.jimdofree.com/">日本列島の甲虫全種目録</a>(鈴木茂様 作)、日本産蛾類については<a href="http://listmj.mothprog.com/">List-MJ 日本産蛾類総目録</a>(神保宇嗣様 作)を、それぞれの作者様のご承諾を頂いてデフォルト分類情報のベースとしました。この場を借りて御礼申し上げます。</Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>現時点での主な不具合・欠陥</Typography>
        <ul className={classes.ul}>
          <LiBody1 text="1、2回程度ログイン作業が繰り返されることがあります。"/>
          <LiBody1 text="現時点ではSafari、Internet Explorer、旧型Edgeはサポートしておりません。Google Chrome、FireFox、Edge(新型)のいずれかからご利用ください。"/>
          <LiBody1 text="スマートフォンからアクセスした際にデフォルトで表示されるモバイル版では表示が崩れます。お手数ですがスマートフォンでもPC版で読み込むようお願いいたします。"/>
          <LiBody1 text="生成されるラベルPDFのサイズやフォントなどは固定されており、カスタマイズすることはできません。"/>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" className={classes.subtitle}>今後の実装予定機能</Typography>
        <Typography variant="h6" className={classes.subsubtitle}>優先順位・高</Typography>
        <ul className={classes.ul}>
          <LiBody1 text="地名入力補助マップの海外対応"/>
          <LiBody1 text="GBIF準拠CSVのダウンロード機能"/>
          <LiBody1 text="標本目録作成機能"/>
        </ul>
        <Typography variant="h6" className={classes.subsubtitle}>優先順位・中</Typography>
        <ul className={classes.ul}>
          <LiBody1 text="ラベル生成機能の自由度向上"/>
          <LiBody1 text="デフォルト分類情報の拡充"/>
          <LiBody1 text="外出中に現在地から指定した半径内にある標本データ表示機能"/>
          <LiBody1 text="採集中に現在地を採集地点として登録する機能"/>
          <LiBody1 text="gpxかkml形式で採集地点や採集ルートをアップロード"/>
        </ul>
        <Typography variant="h6" className={classes.subsubtitle}>優先順位・低</Typography>
        <ul className={classes.ul}>
          <LiBody1 text="標本マップ編集機能"/>
        </ul>
      </Box>
    </Container>

    <LoginSection/>
  </Box>);
}

export default Functions;
