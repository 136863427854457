import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import LoginButton from './LoginButton';

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "100px",
    textAlign: "center",
    backgroundColor: "#e5e5e5",
    paddingTop: "80px",
    paddingBottom: "80px"
  },
  gray: {
    color: "#262626"
  }
}));

function LoginSection() {
  const classes = useStyles();

  return (<Box className={classes.section}>
    <Box m={3}>
      <Typography variant="h6" className={classes.gray}>プライベートな昆虫標本データベースをWebブラウザから簡単に管理</Typography>
    </Box>
    <Box m={3}>
      <LoginButton text="ログイン・登録"/>
    </Box>
  </Box>);
}

export default LoginSection;
