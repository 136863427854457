import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CloseIcon from '@material-ui/icons/Close';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer"
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import LoginButton from './LoginButton';
import Logo from '../img/logo.png';
import News from './News'
import {theme} from '../theme/theme';
import {toServiceLink} from './toServiceLink';
import {useWindowDimensions} from './useWindowDimensions';
import {
  LogoButton,
  LinkButton,
  LinkToManual,
  HeaderLoginButton,
  LogoButtonAsListItem,
  LinkButtonAsListItem,
  LinkToManualAsLlistItem,
  LinkToNews
} from './HeaderComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textDecoration: "none"
  },
  black: {
    backgroundColor: "#000000"
  },
  text: {
    textDecoration: "none"
  },
  lastIcon: {
    marginRight: "5px"
  },
  newsFirstRow: {
    marginRight: "16px",
    marginLeft: "16px",
    padding: 0,
    flexGrow: 1
  },
  flexGorw1: {
    flexGrow: 1
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
}));

function HeaderDesktop() {
  const classes = useStyles();

  return (<Box className={classes.root}>
    <AppBar position="fixed" className={classes.black}>
      <Toolbar>
        <LogoButton textColor="#ffffff"/>
        <LinkButton link="/features/" text="特色" textColor="#ffffff"/>
        <LinkButton link="/functions/" text="機能" textColor="#ffffff"/>
        <LinkButton link="/techinfo/" text="技術情報" textColor="#ffffff"/>
        <LinkButton link="/notice/" text="免責事項" textColor="#ffffff"/>
        <LinkButton link="/contact/" text="ご意見&#183;ご要望" textColor="#ffffff"/>
        <LinkToManual textColor="#ffffff"/>
        <LinkToNews color="secondary"/>
        <HeaderLoginButton/>
      </Toolbar>
    </AppBar>
  </Box>);
}

function HeaderMobile() {
  const classes = useStyles();

  return (<Box className={classes.root}>
    <AppBar position="fixed" className={classes.black}>
      <Toolbar>
        <TemporaryDrawer/>
        <LogoButton textColor="#ffffff"/>
        <HeaderLoginButton/>
      </Toolbar>
    </AppBar>
  </Box>);
}

function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({
      ...state,
      [anchor]: open
    });
  };

  const list = (anchor) => (<div className={classes.fullList} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
    <List>
      <LogoButtonAsListItem textColor="#000000"/>
      <Box mx={2}>
        <LinkToNews color=""/>
      </Box>
      <Divider/>
      <LinkButtonAsListItem link="/features/" text="特色" textColor="#000000"/>
      <LinkButtonAsListItem link="/functions/" text="機能" textColor="#000000"/>
      <LinkButtonAsListItem link="/techinfo/" text="技術情報" textColor="#000000"/>
      <LinkButtonAsListItem link="/notice/" text="免責事項" textColor="#000000"/>
      <LinkButtonAsListItem link="/contact/" text="ご意見&#183;ご要望" textColor="#000000"/>
      <Divider/>
      <LinkToManualAsLlistItem textColor="#000000"/>
    </List>
  </div>);

  return (<div>
    {
      ['left'].map((anchor) => (<React.Fragment key={anchor}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(anchor, true)}>
          <MenuIcon/>
        </IconButton>
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>))
    }
  </div>);
}

function Header() {
  const mobileWidth = 835;
  const {width, height} = useWindowDimensions();
  if (width > mobileWidth) {
    return (<HeaderDesktop/>);
  } else {
    return (<HeaderMobile/>);
  }
}

export default Header;
