import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImgWithShadow from './ImgWithShadow';

import LoginButton from './LoginButton';
import Logo from '../img/logo.png';
import Img from '../img/thumbnail.jpg';
import LoginSection from './LoginSection';
import LogoAndTitle from './LogoAndTitle';
import {useWindowDimensions} from './useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px"
  },
  center: {
    textAlign: "center"
  },
  marginAuto: {
    margin: "auto"
  },
  largeSpace: {
    marginTop: "150px"
  },
  ul: {
    margin: "0",
    padding: "20px",
    paddingLeft: "30px",
    paddingRight: "0"
  },
  li: {
    marginTop: "15px",
    marginBottom: "15px"
  }
}));

function Top() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <LogoAndTitle titleText="WebSpecimanager" subtitleText="クラウド標本管理サービス"/>
      <Box className={classes.center}>
        <LoginButton text="ログイン・登録"/>
      </Box>
    </Container>

    <AboutApp/>

    <LoginSection/>
  </Box>);
}

function LiBody1(props) {
  const classes = useStyles();

  return (<li>
    <Typography variant="body1" className={classes.li}>{props.text}</Typography>
  </li>);
}

function AboutApp() {
  const classes = useStyles();

  return (<Container maxWidth="md" className={classes.largeSpace}>
    <Typography variant="body1">
      WebSpecimanager(ウェブスペシマネジャー)は、プライベートな昆虫標本データベースをWebブラウザから簡単に管理できるサービスです。
    </Typography>
    <ul className={classes.ul}>
      <LiBody1 text="標本データベースを作りたい！ラベル作りを自動化したい！学名や地名の入力なるべくサボりたい！
        でもエクセルとかアクセスとかFileMakerとかで頑張ってシステム自作するのめんどくさい！"/>
      <LiBody1 text="外出先からも標本データベースを確認したい！"/>
      <LiBody1 text="パソコン乗り換える時のデータベース移行作業とかやりたくない"/>
    </ul>
    <Typography variant="body1">そんな虫屋さんにオススメのサービスです。</Typography>
    <Typography variant="body1">独特な操作方法のシステムですので、必ず<a href="https://www.webspecimanager.net/manual/" target="_blank" rel="noopener">マニュアル</a>をお読みになってからご利用ください。</Typography>
    <ImgWithShadow isSmallImg={false} img={Img}/>
    <Typography variant="body1">
      ※サービス側で用意した設定に乗っかることでラクできる代わりに自由度は下がるので、 こだわりの強い方や細かく自分で設定したい方には向きません。
    </Typography>
  </Container>);
}

export default Top;
