import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "colum",
    minHeight: "90vh"
  },
  center: {
    textAlign: "center"
  },
  section: {
    marginTop: "40px",
    marginBottom: "80px"
  },
  bodyText: {
    marginTop: "10px",
    marginBottom: "25px"
  }
}));

function News() {
  const classes = useStyles();

  return (<Box m={0} className={classes.content}>
    <Container maxWidth="md">
      <Box m={3}>
        <Typography variant="h4" className={classes.center}>新着情報</Typography>
        <Typography variant="body1" className={classes.bodyText}>
          機能の追加・修正報告、不具合の報告、メンテナンスの告知などは基本的にTwitter(@webspecimanager)で行いますので、そちらも併せてチェックお願いいたします。
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="subtitle2">2020/6/26</Typography>
        <Typography variant="body1" className={classes.bodyText}>個人収蔵.com様への投稿支援機能を実装しました。</Typography>

        <Typography variant="subtitle2">2020/6/19</Typography>
        <Typography variant="body1" className={classes.bodyText}>多数の新機能の追加に伴い、マニュアルを大幅改定しました。</Typography>

        <Typography variant="subtitle2">2020/3/15</Typography>
        <Typography variant="body1" className={classes.bodyText}>マニュアルに標本一括登録の方法を追加しました。</Typography>

        <Typography variant="subtitle2">2020/3/15</Typography>
        <Typography variant="body1" className={classes.bodyText}>紹介・マニュアルページを公開しました。</Typography>
      </Box>
    </Container>
  </Box>);
}

export default News;
